import { USER_LANGUAGE } from "config/constant";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  // 한국어
  ko: {
    translation: {
      InfoDesk: "Info Desk",
      searchKeyword: "키워드 검색",
      placeholder: "제조사 및 모델명을 입력해 주세요",
      searchFilter: "필터 검색하기",
      recentView: "최근 본 매물",
      recentRegister: "최근 등록 차량",
      recentRegisterSale: "최근 등록 매물",
      discount: "할인가",
      maxSearchRank: "최대 검색 랭킹",
      mostSalesRank: "최다 판매 랭킹",
      recentSearch: "최근 검색어",
      deleteAll: "전체 삭제",
      manufacturer: "제조사",
      price: "가격",
      type: "차종",
      totalSaleCount: "총 매물",
      newSaleThisWeek: "이번주 신상 매물",
      discountSaleThisWeek: "이번주 할인 매물",
      searchCars: "차량검색",
      newVehicles: "매물등록",
      myPage: "마이페이지",
      login: "로그인",
      logout: "로그아웃",
      join: "회원가입",
      selectType: "사용할 회원유형을 등록해 주세요",
      selectTypeDesc: "오토스톡글로벌에서 사용할 회원 유형을 선택해 주세요.",
      buyer: "Buyer 회원",
      seller: "Seller 회원",
      iWantToBuy: "상품을 구매하고 싶어요.",
      iWantToSell: "상품을 판매하고 싶어요.",
      consentPolicy: "정책 및 약관 동의",
      autoStockGlobalTermsOfUse: "오토스톡글로벌 이용약관",
      pleaseAgree:
        "오토스톡글로벌 이용약관 및 개인정보처리방침에 동의해 주세요.",
      agreeRequired: "서비스 이용약관에 동의해 주세요",
      agreeAll: "이용약관 전체 동의",
      ageAbove14: "만 14세 이상입니다. (필수)",
      termsOfUse: "이용약관 (필수)",
      personalInfoCollectionAndUsage: "개인정보 수집 및 이용동의 (필수)",
      marketing: "마케팅 활용 동의 (선택)",
      advertisement: "광고성 수신동의 (선택)",
      next: "다음",
      cancel: "취소",
      confirm: "확인",
      welcome: "환영합니다!",
      completed: "회원가입이 완료되었습니다.",
      welcomeToAutoStockGlobal:
        "오토스톡글로벌 회원이 되신 것을 진심으로 환영합니다.",
      enterUserInfo: "회원 정보 입력",
      pleaseEnterUserInfo: "회원님의 세부정보를 입력해 주세요",
      goToHome: "홈으로 가기",
      goHome: "홈으로",
      country: "국가",
      selectLocation: "국가 또는 지역을 선택해 주세요",
      kr: "한국",
      us: "미국",
      sp: "스페인",
      ru: "러시아",
      enterEmail: "이메일을 입력해 주세요",
      email: "이메일",
      authenticateEmail: "이메일 인증하기",
      invalidEmail: "이메일 형식이 올바르지 않습니다.",
      checkEmail: "이메일을 확인해 주세요.",
      authenticationRequest: "인증요청",
      emailSentCompletely: "이메일 발송 완료",
      emailNotConfirmed: "이메일 인증이 확인되지 않았습니다.",
      pleaseCheckEmail: "이메일 인증을 진행해주세요.",
      sentEmail:
        "{{data}}으로 이메일을 보냈습니다.\n해당 이메일에 있는 링크를 클릭하면 인증이 완료됩니다.",
      alreadyRegistered: "이미 회원가입을 한 상태입니다.",
      askToLogin: "로그인 페이지로 이동하시겠습니까?",
      resend: "재전송",
      emailVerified: "이메일 인증완료",
      emailHasVerifiedCompletely: "이메일 인증이 완료되었습니다.",
      pleaseSelectConfirmButtonToContinueRegister:
        "회원가입을 진행하던 화면으로 돌아가 '확인' 버튼\n클릭 후 회원가입을 이어서 진행해주시기 바랍니다.",
      pleaseContinueRegister: "회원가입 계속하기 버튼을 눌러 주세요.",
      continueRegister: "회원가입 계속하기",
      accountPending: "해당 이메일은 승인 대기 중입니다.",
      willBeApprovedInFewWorkingDays:
        "관리자는 영업일 기준 며칠 이내에 최종 승인을 제공합니다.",
      pleaseEnterPassword: "비밀번호를 입력해 주세요",
      password: "비밀번호",
      enterPassword: "비밀번호 입력",
      confirmPassword: "비밀번호 확인",
      pleaseEnterLongerThan4words: "4자 이상 입력해 주세요.",
      passwordsDoNotMatch: "비밀번호가 일치하지 않습니다.",
      pleaseEnterPhoneNumber: "전화번호를 입력해 주세요",
      phoneNumber: "전화번호",
      enterWithoutDash: "-없이 번호 입력",
      invalidPhoneNumber: "전화번호 형식이 올바르지 않습니다.",
      enterAddress: "인보이스를 수령할 주소를 입력해 주세요",
      address: "주소",
      postCode: "우편번호(필수)",
      findAddress: "주소찾기",
      mainAddress: "주소(필수)",
      detailAddress: "상세주소",
      findPassword: "비밀번호 찾기",
      emailAndPasswordDoNotMatch:
        "이메일 주소 및 비밀번호가 일치하지 않습니다.",
      noMatchingEmail: "유효하지 않은 이메일입니다.",
      askToJoin: "회원가입 페이지로 이동하시겠습니까?",
      resetPassword: "비밀번호 재설정",
      enterEmailAndWillSendOTP:
        "회원가입 시 등록한 이메일을 입력하시면,\n해당 이메일로 임시비밀번호를 발송해드립니다.",
      emailWithOTPSent: "임시비밀번호가 {{data}}으로\n발송완료 되었습니다.",
      enterEmailEnteredWhenJoining: "회원가입 시 등록한 이메일을 입력해 주세요",
      canChangePasswordAfterLogin:
        "(로그인 이후 마이페이지에서 비밀번호 변경이 가능합니다.)",
      user: "회원",
      needLogin: "{{type}} 로그인이 필요한 페이지입니다.",
      filter: "필터",
      recently: "최신등록순",
      min: "낮은 가격순",
      max: "높은 가격순",
      model: "모델",
      minYear: "최소 연식",
      maxYear: "최대 연식",
      year: "연식",
      fuelType: "연료",
      transmissionType: "변속기",
      drivingSystemType: "구동방식",
      num: "승차인원",
      displacement: "배기량",
      option: "옵션",
      color: "색상",
      reset: "초기화",
      viewProduct: "매물보기",
      getNowBtn: "지금 가격 견적 받기",
      purchaseInquiryBtn: "구매 문의하기",
      typeBuyer: "Buyer",
      typeSeller: "Seller",
      myProfile: "내 프로필",
      status: "매물 상태",
      inquiry: "문의",
      inProgress: "진행",
      done: "완료",
      checkWholeStatus: "매물 전체 상태 보기",
      faq: "FAQ",
      historyOfPurchase: "구매 내역",
      all: "전체",
      vehicleInfo: "상품 정보",
      currentStatus: "상태",
      file: "파일",
      submission: "제출",
      waitingForRespond: "답변 대기",
      responded: "답변 완료",
      confirmInvoice: "인보이스 확정",
      confirmINVOICE: "Invoice 확정",
      waitingForDeposit: "입금 확인 중",
      completedPurchase: "구매 완료",
      wantToConfirmInvoice: "인보이스를 확정하시겠습니까?",
      needToContactAfterConfirmIfNeedChange:
        "인보이스 확정 후 인보이스 수정 시 담당자에게 따로 연락해야 합니다.",
      close: "닫기",
      pleaseSelectVehicles: "매물을 선택해 주세요.",
      submitTransferReceiptIn: "{{time}} 안에 송금 영수증 제출",
      invoiceExpired: "인보이스 만료",
      requestInvoiceAgain: "인보이스 재요청",
      transferReceipt: "송금 영수증",
      inquiryHistory: "문의 내역",
      orderNumber: "주문 번호",
      orderDate: "주문 날짜",
      productManagerPhoneNumber: "상품 담당자 전화번호",
      productPrice: "상품 가격",
      shippingPrice: "운임 비용",
      inspectionFee: "점검비",
      total: "총 합계",
      amountDiscounted: "할인",
      additionalPrice: "추가 비용",
      totalPrice: "전체 가격",
      inquiryInfo: "문의 정보",
      editUserProfile: "회원 정보 수정",
      name: "이름",
      language: "소통 언어",
      destinationCountry: "도착 국가",
      destinationPort: "도착 항구",
      vessel: "선박 타입",
      inspection: "성능점검",
      detail: "상세정보",
      options: "옵션정보",
      state: "상태정보",
      copyClipboard: "클립 보드에 복사되었습니다.",
      selectFirst1: "{{content}}를 먼저 선택해주세요",
      selectFirst2: "{{content}}을 먼저 선택해주세요",
      inquiryReceived: "문의가 접수되었습니다.",
      inquiryReceivedDescription:
        "영업시간(9:00AM ~ 6:00PM) 이후의 문의는 익일 처리됩니다.",
      goToInquiry: "문의내역으로 이동하기",
      enterContact: "문의를 위해 연락 정보를 입력해 주세요.",
      inquiryBtn: "문의하기",
      FINE: "양호",
      NORMAL: "보통",
      RUSTED: "심각",
      modelDetail: "세부모델",
      passangers: "인승",
      years: "연도",
      drivenDistance: "주행거리",
      None: "없음",
      Yes: "있음",
      Working: "작동",
      NotWorking: "미작동",
      NotApplicable: "해당없음",
      searchVehicles: "차량 검색",
      pleaseLogin: "로그인해 주세요",
      addCars: "매물 등록",
      myInfo: "내 정보",
      edit: "편집",
      deleteAccount: "회원탈퇴",
      wantToDeleteAccount: "회원탈퇴 하시겠습니까?",
      willBeDeletedRightAway: "요청 즉시 탈퇴 처리 됩니다.",
      languages: "언어",
      search: "검색",
      wantToLogout: "로그아웃 하시겠습니까?",
      editUserInfo: "회원 정보 수정",
      change: "변경",
      save: "저장",
      changePassword: "비밀번호 변경",
      currentPassword: "현재 비밀번호",
      newPassword: "새 비밀번호",
      newConfirmPassword: "새 비밀번호 확인",
      passwordsChangedSuccessfully: "비밀번호가 성공적으로 변경되었습니다.",
      totalCases: "총 {{total}} 건",
      outRange: "최소 입력값이 최대 입력값보다 큽니다.",
      checkInput: "입력값을 다시 확인 해주세요.",
      contactAgain: "해당 연락 정보로 빠른 시일내에 답변 드리겠습니다.",
      introduceInspection: "AUTOSTOCK 인스펙션 소개",
      cargoTrackingShortcutBanner: "카고 트래킹 숏컷 배너",
      snsBanner: "AUTOSTOCK\n SNS 페이스북 배너",
      more: "이상",
      less: "이하",
      yearUnit: "식",
      units: "대",
      noRecentView: "최근 본 매물이 없습니다.",
      noRecentRegister: "최근 등록된 매물이 없습니다.",
      noVideo: "등록된 영상이 없습니다.",
      noModel: "일치하는 모델 없음",
      noMatching: "일치하는 {{type}} 없음",
      lowerBodyCondition: "Lower Body Condition",
      operationCondition: "Operation Condition",
      corrosionCondition: "Corrosion Condition",
      holes: "Holes",
      broken: "Broken",
      engine: "Engine",
      transmission: "Transmission",
      brake: "Brake",
      airConditioner: "Air Conditioner",
      heater: "Heater",
      audio: "Audio",
      window: "Window",
      "4WDWorking": "4WD Working",
      nonMember: "비회원으로 시작하기",
      TYPE: "타입",
      announcement: "공지사항",
      searchUsingFilter: "필터검색",
    },
  },
  // 영어
  en: {
    translation: {
      passangers: "Passengers",
      years: "Year",
      drivenDistance: "Mileage",
      InfoDesk: "Info Desk",
      None: "None",
      Yes: "Yes",
      Working: "Working",
      NotWorking: "Not Working",
      NotApplicable: "None",
      searchKeyword: "Keyword Search",
      placeholder: "Please enter the manufacturar and model",
      searchFilter: "Search by filter",
      recentView: "Recently viewed",
      recentRegister: "Recently registered",
      recentRegisterSale: "Recently registered products",
      discount: "Sale price",
      maxSearchRank: "Most searched",
      mostSalesRank: "Most sold",
      recentSearch: "Recently searched",
      deleteAll: "Delete all",
      manufacturer: "Maker",
      price: "Price",
      type: "Type",
      totalSaleCount: "All products",
      newSaleThisWeek: "New products this week",
      discountSaleThisWeek: "Products discounted this week",
      searchCars: "Search",
      newVehicles: "Upload",
      myPage: "My page",
      login: "Login",
      logout: "Logout",
      join: "Create Account",
      selectType: "Select your account type",
      selectTypeDesc: "Please select your membership type",
      buyer: "Buyer Account",
      seller: "Seller Account",
      iWantToBuy: "I want to buy",
      iWantToSell: "I want to sell",
      consentPolicy: "Terms and Conditions",
      autoStockGlobalTermsOfUse: "Terms of use or Autostock Global",
      pleaseAgree:
        "Agree the terms of use and privacy policies of Autostock Global",
      agreeRequired: "Agree terms and conditions",
      agreeAll: "Agree all terms and condition",
      ageAbove14: "I'm 14 years older (Required)",
      termsOfUse: "Terms and Conditions (Required)",
      personalInfoCollectionAndUsage:
        "Consent to collection and use of personal information (Required)",
      marketing: "Agree marketing terms (Optional)",
      advertisement: "Agree to receive advertising (Optional)",
      next: "Next",
      cancel: "Cancel",
      confirm: "Accept",
      welcome: "Welcome!",
      completed: "Registration complete",
      welcomeToAutoStockGlobal: "We sincerely welcome you to Autostock Global",
      enterUserInfo: "Enter your membership information",
      pleaseEnterUserInfo: "Enter detailed membership information",
      goToHome: "Home",
      goHome: "Home",
      country: "Country",
      selectLocation: "Select your country or region",
      kr: "한국",
      us: "USA",
      sp: "Spain",
      ru: "Russia",
      enterEmail: "Please enter your email",
      email: "Email",
      authenticateEmail: "Verify Email",
      invalidEmail: "The Email format is not valid.",
      checkEmail: "Check your email",
      authenticationRequest: "Authentication request",
      emailSentCompletely: "Email sent",
      emailNotConfirmed: "Email verification has not been confirmed",
      pleaseCheckEmail: "Proceed email verification",
      sentEmail:
        "An email has been sent to {{data}}.\nClick the link in that email to complete verification",
      alreadyRegistered: "Already registered",
      askToLogin: "Would you like to go to the login screen?",
      resend: "Send again",
      emailVerified: "Email verification completed",
      emailHasVerifiedCompletely: "Email verification completed",
      pleaseSelectConfirmButtonToContinueRegister:
        "Please go back to the registration page,\nand click 'Continue' button to continue registration",
      pleaseContinueRegister: "Please click Continue registration button",
      continueRegister: "Continue registration",
      pleaseEnterPassword: "Please enter password",
      password: "Password",
      enterPassword: "Enter password",
      confirmPassword: "Confirm password",
      pleaseEnterLongerThan4words: "Enter at least 4 characters",
      passwordsDoNotMatch: "Password doesn't match",
      pleaseEnterPhoneNumber: "Enter phone number",
      phoneNumber: "Phone number",
      enterWithoutDash: `Enter without " - "`,
      invalidPhoneNumber: "The phone number format is not valid",
      enterAddress: "Enter the address to receive invoice",
      address: "Address",
      postCode: "Post Code",
      findAddress: "Search Address",
      mainAddress: "Address (Required)",
      detailAddress: "Detailed Address",
      findPassword: "Find Password",
      emailAndPasswordDoNotMatch: "Email or Password not valid",
      noMatchingEmail: "Email not valid",
      askToJoin: "Would you like to go registration page?",
      resetPassword: "Reset password",
      enterEmailAndWillSendOTP:
        "Enter your email and your temporary password will be sent to your email.",
      emailWithOTPSent: "Your temporary password has been sent to {{data}}",
      enterEmailEnteredWhenJoining: "Enter the email you used to register",
      canChangePasswordAfterLogin:
        "(You can change your password on My Page after logging in.)",
      user: "User",
      needLogin: "Requires {{type}} login",
      filter: "filter",
      recently: "Recently registered",
      min: "Min price",
      max: "Max price",
      model: "Model",
      minYear: "Min Year",
      maxYear: "Max Year",
      year: "Year",
      fuelType: "Fuel",
      transmissionType: "Transmission",
      drivingSystemType: "Drive Type",
      num: "Passengers",
      displacement: "CC",
      option: "Options",
      color: "Color",
      reset: "Reset",
      viewProduct: "View product",
      getNowBtn: "Get quote now",
      purchaseInquiryBtn: "Purchase Inquiry",
      typeBuyer: "Buyer",
      typeSeller: "Seller",
      myProfile: "My profile",
      status: "Status",
      inquiry: "Inquiries",
      inProgress: "Process",
      done: "Finish",
      checkWholeStatus: "See all",
      faq: "FAQ",
      historyOfPurchase: "Purchase detail",
      all: "Total",
      vehicleInfo: "Product info",
      currentStatus: "Status",
      file: "File",
      submission: "Submission",
      waitingForRespond: "Waiting answer",
      responded: "Answer complete",
      confirmInvoice: "Invoice confirmed",
      confirmINVOICE: "Confirm invoice",
      waitingForDeposit: "Waiting bank transfer confirmation",
      completedPurchase: "Purchase completed",
      wantToConfirmInvoice: "Do you want to confirm invoice?",
      needToContactAfterConfirmIfNeedChange:
        "After the invoice is confirmed for edit it you have to contact sales team",
      close: "Close",
      pleaseSelectVehicles: "Please select product.",
      submitTransferReceiptIn: "Subit remittance receipt within {{time}}",
      invoiceExpired: "Expired Invoice",
      requestInvoiceAgain: "Request Invoice again",
      transferReceipt: "Wire transfer receipt",
      inquiryHistory: "Inquiry history",
      orderNumber: "Order number",
      orderDate: "Order date",
      productManagerPhoneNumber: "Product manager contact number",
      productPrice: "Price",
      shippingPrice: "Shipping fee",
      inspectionFee: "Inspection fee",
      total: "Total",
      amountDiscounted: "Discount",
      additionalPrice: "Extra fee",
      totalPrice: "Total",
      inquiryInfo: "Inquiries",
      editUserProfile: "Edit information",
      name: "Name",
      language: "Language",
      destinationCountry: "Country",
      destinationPort: "Port",
      vessel: "Vessel",
      inspection: "Inspection",
      detail: "Detail info",
      options: "Options",
      state: "Status info",
      copyClipboard: "Copied to clipboard",
      selectFirst1: "Select {{content}}",
      selectFirst2: "Select {{content}}",
      inquiryReceived: "Your inquiry has been received",
      inquiryReceivedDescription:
        "Inquiries after business hours (9:00AM ~ 6:00PM) (UTC+09:00) will be processed the next day.",
      goToInquiry: "Go to my inquiries",
      enterContact: "Enter your contact number for quote",
      inquiryBtn: "Make inquiry",
      FINE: "FINE",
      NORMAL: "NORMAL",
      RUSTED: "RUSTED",
      searchVehicles: "Search unit",
      pleaseLogin: "Please login",
      addCars: "Upload unit",
      myInfo: "My info",
      edit: "Edit",
      deleteAccount: "Sign out",
      wantToDeleteAccount: "Do you want to cancel your membership?",
      willBeDeletedRightAway:
        "Your request for withdrawal will be processed immediately.",
      languages: "Languages",
      wantToLogout: "Do you want to log out?",
      editUserInfo: "Edit information",
      change: "Edit",
      save: "Save",
      changePassword: "Edit password",
      currentPassword: "Current password",
      newPassword: "New password",
      newConfirmPassword: "Confirm new password",
      passwordsChangedSuccessfully: "Your password changed successfully",
      modelDetail: "Model detail",
      totalCases: "Total {{total}} units",
      outRange: "The minimum value is greater than the maximum value",
      checkInput: "Please check your input values again.",
      contactAgain:
        "We will respond as soon as possible using the relevant contact information.",
      introduceInspection: "Introduction to Auto Stock Inspection",
      cargoTrackingShortcutBanner: "Cargo tracking shortcut banner",
      snsBanner: "AUTOSTOCK\n SNS facebook banner",
      more: "More",
      less: "Less",
      yearUnit: "Year",
      units: "Units",
      noRecentView: "There are no recently viewed products.",
      noRecentRegister: "There are no recently products.",
      noVideo: "There are no registered videos.",
      noModel: "No matching models",
      noMatching: "No matching {{type}}",
      lowerBodyCondition: "Lower Body Condition",
      operationCondition: "Operation Condition",
      corrosionCondition: "Corrosion Condition",
      holes: "Holes",
      broken: "Broken",
      engine: "Engine",
      transmission: "Transmission",
      brake: "Brake",
      airConditioner: "Air Conditioner",
      heater: "Heater",
      audio: "Audio",
      window: "Window",
      "4WDWorking": "4WD Working",
      nonMember: "Get started as a non-member",
      TYPE: "Type",
      announcement: "Announcement",
      searchUsingFilter: "Filter Search",
    },
  },
  // 스페인어
  es: {
    translation: {
      passangers: "Passjeros",
      years: "Año",
      drivenDistance: "Kilometraje",
      InfoDesk: "Info Desk",
      searchKeyword: "Búsqueda por palabra clave",
      placeholder: "Por favor ingrese el fabricante y modelo",
      searchFilter: "Búsqueda por filtro",
      recentView: "Vístos recientemente",
      recentRegister: "Registrados recientemente",
      recentRegisterSale: "Productos registrados recientemente",
      discount: "Oferta",
      maxSearchRank: "Más buscados",
      mostSalesRank: "Más vendidos",
      recentSearch: "Buscados recientemente",
      deleteAll: "Borrar todo",
      manufacturer: "Fabricante",
      price: "Precio",
      type: "Segmento",
      totalSaleCount: "Todos los productos",
      newSaleThisWeek: "Nuevos productos esta semana",
      discountSaleThisWeek: "Productos con descuento esta semana",
      searchCars: "Buscar",
      newVehicles: "Publicar",
      myPage: "Mi página",
      login: "Iniciar sesión",
      logout: "Finalizar sesión",
      join: "Crear Cuenta",
      selectType: "Seleccione su tipo de cuenta",
      selectTypeDesc: "Por favor seleccione su tipo de membresía",
      buyer: "Comprador",
      seller: "Vendedor",
      iWantToBuy: "Quiero comprar",
      iWantToSell: "Quiero vender",
      consentPolicy: "Aceptar políticas y términos",
      autoStockGlobalTermsOfUse: "Términos de uso de Autostock Global",
      pleaseAgree:
        "Aceptar los términos de uso y políticas de privacidad de Autostock Global.",
      agreeRequired: "Aceptar términos y condiciones",
      agreeAll: "Aceptar todos los términos y condiciones",
      ageAbove14: "Soy mayor de 14 años (Requerido)",
      termsOfUse: "Términos y Condiciones (Requerido)",
      personalInfoCollectionAndUsage:
        "Consentimiento para la recopilación y uso de información personal (Requerido)",
      marketing: "Aceptar los términos de marketing (Opcional)",
      advertisement: "Aceptar los términos de publicidad (Opcional)",
      next: "Siguiente",
      cancel: "Cancelar",
      confirm: "Aceptar",
      welcome: "Bienvenidos!",
      completed: "Registro finalizado",
      welcomeToAutoStockGlobal:
        "Le damos la damos la más sincera bienvenida a Autostock Global",
      enterUserInfo: "Ingresar información de la membresía",
      pleaseEnterUserInfo: "Ingresar información detallada de la membresía",
      goToHome: "Volver al inicio",
      goHome: "Inicio",
      country: "País",
      selectLocation: "Seleccione su país o región",
      kr: "한국",
      us: "USA",
      sp: "España",
      ru: "Rusia",
      enterEmail: "Ingrese su correo electrónico",
      email: "Correo Electrónico",
      authenticateEmail: "Verificar correo eletrónico",
      invalidEmail: "El formato no es válido",
      checkEmail: "Revise su correo electrónico",
      authenticationRequest: "Solicitar autentificación",
      emailSentCompletely: "Correo electrónico enviado",
      emailNotConfirmed:
        "La autentificación del correo electrónico no ha sido confirmada.",
      pleaseCheckEmail: "Proceda con la autentificación del correo electrónico",
      sentEmail:
        "Se ha enviado un correo electrónico a {{data}}.\nHaga clic en el enlace de ese correo electrónico para completar la verificación",
      alreadyRegistered: "Ya registrado",
      askToLogin: "¿Desea ir a la pantalla de inicio de sesión?",
      resend: "Reenviar",
      emailVerified: "Verificación de correo electrónico completo",
      emailHasVerifiedCompletely: "Verificación de correo electrónico completo",
      pleaseSelectConfirmButtonToContinueRegister:
        "Regrese a la página de registro y haga clic en 'Continuar' para continuar con el registro.",
      pleaseContinueRegister:
        "Por favor haga click en el botón Continuar registro",
      continueRegister: "Continuar registro",
      pleaseEnterPassword: "Por favor ingrese la contraseña",
      password: "Contraseña",
      enterPassword: "Ingresar contraseña",
      confirmPassword: "Confirmar contraseña",
      pleaseEnterLongerThan4words: "Ingresar mínimo 4 caracteres",
      passwordsDoNotMatch: "La contraseña no concuerda",
      pleaseEnterPhoneNumber: "Ingresar número de teléfono",
      phoneNumber: "Número de teléfono",
      enterWithoutDash: `Ingrese sin " - "`,
      invalidPhoneNumber: "El formato no es válido",
      enterAddress: "Ingresar dirección para recibir el invoice",
      address: "Dirección",
      postCode: "Código Postal",
      findAddress: "Buscar Dirección",
      mainAddress: "Dirección (Requerido)",
      detailAddress: "Dirección detallada",
      findPassword: "Buscar Contraseña",
      emailAndPasswordDoNotMatch: "Correo Electrónico o Contraseña no válido",
      noMatchingEmail: "Correo Electrónico no válido",
      askToJoin: "¿Desea ir a la página de registro?",
      resetPassword: "Restablecer contraseña",
      enterEmailAndWillSendOTP:
        "Introduce tu correo electrónico y te enviaremos una contraseña temporal",
      emailWithOTPSent: "Su contraseña temporal ha sido enviada a {{data}}",
      enterEmailEnteredWhenJoining:
        "Ingresar el correco electrónico que utilizó para la registración",
      canChangePasswordAfterLogin:
        "(Puede cambiar su contraseña en Mi página después de iniciar sesión).",
      user: "Usuario",
      needLogin: "Requiere iniciar sesión de {{type}}",
      filter: "Filtro",
      recently: "Registrados recientemente",
      min: "Precio mínimo",
      max: "Precio máximo",
      model: "Modelo",
      minYear: "Año mínimo",
      maxYear: "Año máximo",
      year: "Año",
      fuelType: "Combustible",
      transmissionType: "Transmisión",
      drivingSystemType: "Tracción",
      num: "Pasajeros",
      displacement: "Cilindrada",
      option: "Opciones",
      color: "Color",
      reset: "Reiniciar",
      viewProduct: "Ver producto",
      getNowBtn: "Recibir presupuesto ahora",
      purchaseInquiryBtn: "Consultar compra",
      typeBuyer: "Comprador",
      typeSeller: "Vendedor",
      myProfile: "Mi Perfil",
      status: "Estado",
      inquiry: "Consultas",
      inProgress: "Proceso",
      done: "Finalizado",
      checkWholeStatus: "Ver todo",
      faq: "Preguntas frecuentes",
      historyOfPurchase: "Historial de compra",
      all: "Total",
      vehicleInfo: "Información del producto",
      currentStatus: "Estado",
      file: "File",
      submission: "Presentación",
      waitingForRespond: "Esperando respuesta",
      responded: "Respuesta completa",
      confirmInvoice: "Invoice confirmado",
      confirmINVOICE: "Confirmar invoice",
      waitingForDeposit: "Esperando confirmación de transferencia bancaria",
      completedPurchase: "Compra completada",
      wantToConfirmInvoice: "Desea confirmar el invoice?",
      needToContactAfterConfirmIfNeedChange:
        "Una vez confirmao el invoice para editarla debe contactar al equipo de ventas",
      close: "Cerrar",
      pleaseSelectVehicles: "Por favor seleccione productos",
      submitTransferReceiptIn: "Subit remittance receipt within {{time}}",
      invoiceExpired: "Invoice vencido",
      requestInvoiceAgain: "volver a solicitar factura",
      transferReceipt: "Recibo de transferencia bancaria",
      inquiryHistory: "Historial de consultas",
      orderNumber: "Número de orden",
      orderDate: "Fecha de orden",
      productManagerPhoneNumber:
        "Número de contacto del encargado del producto",
      productPrice: "Precio",
      shippingPrice: "Inspection Fee",
      inspectionFee: "Costo de inspección",
      total: "Total",
      amountDiscounted: "Descuento",
      additionalPrice: "Cargo extra",
      totalPrice: "Total",
      inquiryInfo: "Información de la consulta",
      editUserProfile: "Editar información",
      name: "Nombre",
      language: "Idioma",
      destinationCountry: "País",
      destinationPort: "Puerto",
      vessel: "Embarque",
      inspection: "Inspección",
      detail: "Detalle",
      options: "Opciones",
      state: "Estado",
      copyClipboard: "Copiado al portapapeles",
      selectFirst1: "Seleccionar {{content}}",
      selectFirst2: "Seleccionar {{content}}",
      inquiryReceived: "Se ha registrado su consulta",
      inquiryReceivedDescription:
        "Las consultas fuera del horario comercial (9:00 a. m. ~ 6:00 p. m.) (UTC+09:00) se procesarán al día siguiente.",
      goToInquiry: "Ir a mis consultas",
      enterContact: "Ingrese su número de contacto para recibir el presupuesto",
      inquiryBtn: "Hacer consulta",
      FINE: "BIEN",
      NORMAL: "NORMAL",
      RUSTED: "OXIDADO",
      searchVehicles: "Buscar",
      pleaseLogin: "Inicie sesión",
      addCars: "Publicar",
      myInfo: "Mi información",
      edit: "Edit",
      deleteAccount: "Cancelar membresía",
      wantToDeleteAccount: "¿Quieres cancelar tu membresía?",
      willBeDeletedRightAway:
        "Su solicitud de retiro será procesada de forma inmediata.",
      languages: "Idioma",
      editUserInfo: "Editar informacion",
      change: "Cambiar",
      save: "Guardar",
      changePassword: "Cambiar contraseña",
      currentPassword: "Contraseña actual",
      newPassword: "Nueva contraseña",
      newConfirmPassword: "Confirmar nueva contraseña",
      passwordsChangedSuccessfully: "Su contraseña ha sido cambiado con éxito",
      modelDetail: "Modelo detallado",
      totalCases: "Total {{total}} unidades",
      outRange: "El valor mínimo es mayor que el valor máximo.",
      checkInput: "Verifique sus valores de entrada nuevamente.",
      contactAgain:
        "Le responderemos lo antes posible utilizando la información de contacto relevante.",
      introduceInspection:
        "Introducción a la inspección de existencias de automóviles",
      cargoTrackingShortcutBanner:
        "Banner de acceso directo al seguimiento de carga",
      snsBanner: "banner de facebook de AUTOSTOCK sns",
      more: "Más",
      less: "Menos",
      yearUnit: "Año",
      units: "Unidades",
      noRecentView: "No hay productos vistos recientemente.",
      noRecentRegister: "No hay productos recientemente.",
      noVideo: "No hay vídeos registrados.",
      noModel: "No hay modelos coincidentes",
      None: "Ninguno",
      Yes: "Sí",
      Working: "Funcionando",
      NotWorking: "No Funcionando",
      NotApplicable: "Ninguno",
      noMatching: "Ningún {{type}} coincidente",
      lowerBodyCondition: "Condición inferior",
      operationCondition: "Condición de operación",
      corrosionCondition: "Corrosión inferior",
      holes: "Agujeros",
      broken: "Roturas",
      engine: "Motor",
      transmission: "Transmisión",
      brake: "Frenos",
      airConditioner: "Aire acondicionado",
      heater: "Calefacción",
      audio: "Audio",
      window: "Ventana",
      "4WDWorking": "4WD funcionando",
      nonMember: "Comience como no miembro",
      TYPE: "Tipo",
      announcement: "Anuncio",
      searchUsingFilter: "Filtrar búsqueda",
    },
  },
  // 러시아어
  ru: {
    translation: {
      InfoDesk: "Info Desk",
      searchKeyword: "ключевое слово",
      placeholder: "пожалуйста укажите производителя или модель",
      searchFilter: "поиск по фильтру",
      recentView: "недавно просмотренные",
      recentRegister: "недавно зарегистрированные",
      recentRegisterSale: "Недавно зарегистрированные продукты",
      discount: "на скидке",
      maxSearchRank: "наиболее разыскиваемые",
      mostSalesRank: "наиболее продаваемые",
      recentSearch: "недавно в поисках",
      deleteAll: "удалить все",
      manufacturer: "производитель",
      price: "цена",
      type: "модель",
      totalSaleCount: "Все продукты",
      newSaleThisWeek: "Новые товары на этой неделе",
      discountSaleThisWeek: "Товары со скидкой на этой неделе",
      searchCars: "Поиск автомобиля",
      newVehicles: "Регистрация собственности",
      myPage: "Моя Страница",
      login: "войти в систему",
      logout: "выйти из системы",
      join: "зарегистрироваться",
      selectType: "Выберите тип членства, который вы хотите использовать.",
      selectTypeDesc:
        "Пожалуйста, выберите тип членства, который вы хотите использовать в Autostock Global.",
      buyer: "член покупатель",
      seller: "хочу купить продукт",
      iWantToBuy: "хочу купить продукт",
      iWantToSell: "хочу продать продукт",
      consentPolicy: "соглащения c положениями и условиями",
      autoStockGlobalTermsOfUse: "Условия использования Autostock Global",
      pleaseAgree:
        "Пожалуйста, согласитесь с условиями использования и политикой конфиденциальности Autostock Global.",
      agreeRequired: "согласен с условиями и положениями",
      agreeAll: "согласиться со всеми условиями и положениями",
      ageAbove14: "мне больше 14 лет (обязательный)",
      termsOfUse: "условия и положения (обязательный)",
      personalInfoCollectionAndUsage:
        "согласие на сбор и использование личной информации",
      marketing:
        "согласие на использование в целях маркетинга (не обязательный)",
      advertisement: "согласие на получение рекламы (не обязательный)",
      next: "следующий",
      cancel: "отменить",
      confirm: "принять",
      welcome: "добро пожаловать!",
      completed: "регистрация успешна",
      welcomeToAutoStockGlobal:
        "Мы рады приветствовать Вас в качестве члена Autostock Global.",
      enterUserInfo: "Введите информацию о своем членстве",
      pleaseEnterUserInfo: "Пожалуйста, введите свои данные",
      goToHome: "главная страница",
      goHome: "главная страница",
      country: "страна",
      selectLocation: "выберите страну или регион",
      kr: "Южная Корея",
      us: "США",
      sp: "Испания",
      ru: "Россия",
      enterEmail: "введите адрес электронной почты",
      email: "электронная почта",
      authenticateEmail: "подтверждение электронной почты",
      invalidEmail: "неверный формат электронной почты",
      checkEmail: "проверьте вашу электронную почту",
      authenticationRequest: "Запросы на аутентификацию",
      emailSentCompletely: "Завершите отправку электронного письма",
      emailNotConfirmed: "Адрес электронной почты не подтвержден.",
      pleaseCheckEmail: "Пожалуйста, пройдите верификацию электронной почты.",
      sentEmail:
        "вам было отправлено электронное письмо на {{data}}.\nДля завершения подтверждения, нажмите на ссылку в письме.",
      alreadyRegistered: "ты уже зарегистрировался", // 구글 번역
      askToLogin: "Хотите перейти на страницу входа?",
      resend: "отправить снова",
      emailVerified: "завершение подтверждения электронной почты",
      emailHasVerifiedCompletely: "проверка электронной почты завершена", // 구글 번역
      pleaseSelectConfirmButtonToContinueRegister:
        "Пожалуйста, вернитесь на страницу регистрации и нажмите кнопку «Продолжить»,\nчтобы продолжить регистрацию.", // 구글 번역
      pleaseContinueRegister: 'нажмите на кнопку "продолжить регистрацию"',
      continueRegister: "продолжить регистрацию",
      accountPending: "해당 이메일은 승인 대기 중입니다.", // seller
      willBeApprovedInFewWorkingDays:
        "관리자는 영업일 기준 며칠 이내에 최종 승인을 제공합니다.", // seller
      pleaseEnterPassword: "пожалуйста введите пароль",
      password: "пароль",
      enterPassword: "введите пароль",
      confirmPassword: "подтвердите пароль",
      pleaseEnterLongerThan4words: "введите минимум 4 символа",
      passwordsDoNotMatch: "пароли не совпадают",
      pleaseEnterPhoneNumber: "введите номер телефона",
      phoneNumber: "номер телефона",
      enterWithoutDash: 'введите без " - "',
      invalidPhoneNumber: "неверный формат номера телефона",
      enterAddress: "введите адрес для получения квитанции",
      address: "адрес",
      postCode: "почтовый индекс",
      findAddress: "искать адрес",
      mainAddress: "адрес (обязательный)",
      detailAddress: "подробный адрес",
      findPassword: "забыли пароль?",
      emailAndPasswordDoNotMatch: "неверный адрес электронной почты или пароль",
      noMatchingEmail: "несуществующий адрес электронной почты",
      askToJoin: "хотите перейти на страницу 'создать новый аккаунт'?",
      resetPassword: "Сброс пароля",
      enterEmailAndWillSendOTP:
        "введите свой адрес электронной\nпочты и мы вышлем вам временный пароль", // 구글번역
      emailWithOTPSent: "ваш временный пароль былотправлен на\n{{data}}",
      enterEmailEnteredWhenJoining:
        "введите адрес электронной почты, который вы использовали при регистрации",
      canChangePasswordAfterLogin:
        "(вы можете поменять свой пароль на Моя Страница после авторизации)",
      user: "Пользователь",
      needLogin: "требуется логин {{type}}",
      filter: "фильтр",
      recently: "недавно зарегистрированные",
      min: "минимальная цена",
      max: "максимальная цена",
      model: "модель",
      minYear: "Мин год",
      maxYear: "Макс. год",
      year: "год",
      fuelType: "топливо",
      transmissionType: "трансмиссия ",
      drivingSystemType: "тип привода",
      num: "пассажиры",
      displacement: "объем двигателя",
      option: "опции",
      color: "цвет",
      reset: "сбросить",
      viewProduct: "посмотреть товар",
      getNowBtn: "получить ценовую оценку сейчас",
      purchaseInquiryBtn: "запрос на покупку",
      typeBuyer: "покупателя",
      typeSeller: "продавца",
      myProfile: "Мой профиль",
      status: "Статус",
      inquiry: "запрос",
      inProgress: "процесс",
      done: "завершение",
      checkWholeStatus: "Просмотр полного статуса",
      faq: "Вопросы и ответы",
      historyOfPurchase: "История покупок",
      all: "общее",
      vehicleInfo: "Информация о продукте",
      currentStatus: "Статус",
      file: "вложение", // 구글 번역
      submission: "подчинение", // 구글 번역
      waitingForRespond: "В ожидании ответа",
      responded: "Отвечено",
      confirmInvoice: "Завершить выставление счета",
      confirmINVOICE: "Подтверждение счета-фактуры ",
      waitingForDeposit: "Подтверждение оплаты",
      completedPurchase: "Покупка завершена",
      wantToConfirmInvoice: "Вы хотите завершить свой счет-фактуру?",
      needToContactAfterConfirmIfNeedChange:
        "После подтверждения счета-фактуры вам нужно будет отдельно связаться с ответственным лицом, чтобы исправить счет-фактуру.",
      close: "закрыть",
      pleaseSelectVehicles: "Пожалуйста, выберите транспортные средства", // 구글 번역
      submitTransferReceiptIn: "Отправить квитанцию о переводе до {{time}}",
      invoiceExpired: "Истечение срока действия счета",
      requestInvoiceAgain: "Запросить счет повторно",
      transferReceipt: "Квитанция о денежном переводе",
      inquiryHistory: "история расследования", // 구글 번역
      orderNumber: "Номер заказа",
      orderDate: "Дата заказа",
      productManagerPhoneNumber: "Контактный телефон продукта",
      productPrice: "цена автомобиля",
      shippingPrice: "цена за доставку",
      inspectionFee: "инспекционные расходы",
      total: "общая сумма",
      amountDiscounted: "скидка",
      additionalPrice: "Дополнительные расходы",
      totalPrice: "Общая цена",
      inquiryInfo: "Свяжитесь с нами",
      editUserProfile: "редактирование профиля пользователя", // 구글 번역
      name: "имя",
      language: "Коммуникативный язык",
      destinationCountry: "страна",
      destinationPort: "порт",
      vessel: "судно",
      inspection: "инспекция",
      detail: "подробно",
      options: "опции",
      state: "информация о состоянии",
      copyClipboard: "Скопировано в буфер обмена.",
      selectFirst1: "{{content}}를 먼저 선택해주세요",
      selectFirst2: "{{content}}을 먼저 선택해주세요",
      inquiryReceived: "Запрос получен.",
      inquiryReceivedDescription:
        "Запросы в нерабочее время (9:00 ~ 18:00) (UTC+09:00) будут обработаны на следующий день.",
      goToInquiry: "Перейти к истории запросов",
      enterContact:
        "Пожалуйста, введите свои контактные данные, чтобы сделать запрос.",
      inquiryBtn: "Сделать запрос",
      FINE: "хорошее",
      NORMAL: "среднее",
      RUSTED: "ржавый",
      modelDetail: "модель",
      passangers: "пассажиры",
      years: "год",
      drivenDistance: "километраж",
      None: "Никто",
      Yes: "Да",
      Working: "Работающий",
      NotWorking: "Не работает",
      NotApplicable: "Никакой",
      searchVehicles: "Поиск автомобиля",
      pleaseLogin: "пожалуйста ввойдите в систему",
      addCars: "загрузить",
      myInfo: "Обо мне",
      edit: "редактировать",
      deleteAccount: "Прекращение членства",
      wantToDeleteAccount: "Вы хотите отказаться от членства?",
      willBeDeletedRightAway: "Запрос будет выполнен немедленно.",
      languages: "Язык",
      search: "поиск", // 구글 번역
      wantToLogout: "Вы хотите выйти из системы?",
      editUserInfo: "редактировать информацию о пользователе", // 구글 번역
      change: "изменять", // 구글 번역
      save: "сохранить",
      changePassword: "Смена пароля",
      currentPassword: "Текущий пароль",
      newPassword: "Новый пароль",
      newConfirmPassword: "Подтвердите новый пароль",
      passwordsChangedSuccessfully: "Пароль был успешно изменен.",
      totalCases: "общее {{total}} количество",
      outRange:
        "минимальное входное значение больше чем максимальное входное значение.",
      checkInput: "пожалуйста перепроверьте входное значение",
      contactAgain:
        "мы вам ответим в скорейшее время по данной контактной информации",
      introduceInspection: "представление иснпекции AUTOSTOCK",
      cargoTrackingShortcutBanner: "Фейсбук баннер AUTOSTICK SNS",
      snsBanner: "Баннер по ярлыку отслеживания грузов",
      more: "более",
      less: "менее",
      yearUnit: "год модели",
      units: "~штук (2штук)",
      noRecentView: "Недавно просмотренных товаров нет.",
      noRecentRegister: "Свежих товаров нет.",
      noVideo: "Нет зарегистрированных видео.",
      noModel: "Нет подходящих моделей",
      noMatching: "Нет подходящего {{type}}",
      lowerBodyCondition: "Состояние нижней части тела",
      operationCondition: "Условия эксплуатации",
      corrosionCondition: "Коррозионное состояние",
      holes: "Отверстия",
      broken: "Сломанный",
      engine: "Двигатель",
      transmission: "трансмиссия",
      brake: "Тормоз",
      airConditioner: "Кондиционер",
      heater: "Нагревательный прибор",
      audio: "Аудио",
      window: "Окно",
      "4WDWorking": "4WD Рабочий",
      nonMember: "Начните как не участник",
      TYPE: "тип",
      announcement: "объявление",
      searchUsingFilter: "Фильтровать поиск",
    },
  },
};

i18n
  .use(initReactI18next)
  .init({ resources, lng: sessionStorage.getItem(USER_LANGUAGE) ?? "en" });

export default i18n;
