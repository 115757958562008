import { createGlobalStyle } from "styled-components";

export const supportDeviceSize = 1079;

export const device = {
  mobile: `max-width: ${supportDeviceSize}px`,
};

export const GlobalStyle = createGlobalStyle`
html {
font-size: 62.5%; // 1rem = 10px 로 변경 한 것
// 참고링크 = https://stackoverflow.com/questions/68790660/setting-root-font-size-not-affecting-rem-units-in-safari-for-margin-padding-et
// 128px => 12.8rem , 4px => 0.4rem 가능!

    @media all and (${device.mobile}) {
    }
}

body {
    background: white;
    margin: 0;
    padding: 0;
    font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

ul {
  padding: 0;
  margin: 0;
}

fieldset, input, label {
  all: unset;
}

button {
  background: none;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
}

* {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
`;
