import { countries } from "countries-list";

export const COUNTRIES = Object.entries(countries).sort((a, b) =>
  a[1].name.localeCompare(b[1].name)
);

export const JWT_KEY = "jwt";
export const FIREBASE_EXPORT_DECLARATION_CERTIFICATE_DIRECTORY = `${process.env.REACT_APP_FIREBASE_DIRECTORY}/certificate/exportDeclaration`;
export const AWS_EXPORT_DECLARATION_CERTIFICATE_DIRECTORY = `${process.env.REACT_APP_AWS_DIRECTORY}/certificate/exportDeclaration`;
export const AWS_BANK_BOOK_DIRECTORY = `${process.env.REACT_APP_AWS_DIRECTORY}/join/bankBookImage`;
export const AWS_PROOF_OF_BUSINESS_DIRECTORY = `${process.env.REACT_APP_AWS_DIRECTORY}/join/proofOfBusinessImage`;
export const AWS_RECEIPT_DIRECTORY = `${process.env.REACT_APP_AWS_DIRECTORY}/receipt`;
export const AWS_CARFORSALE_UPLOAD_DIRECTORY = `${process.env.REACT_APP_AWS_DIRECTORY}/carForSaleImage/origin/`;

export const COUNTRY_NAMES = COUNTRIES.map(
  (country: any) => `${country[1].name} (${country[0]})`
);
export const COUNTRY_CODES = COUNTRIES.map((country) => ({
  name: country[1].name,
  code: `+${country[1].phone}`,
})).sort((a, b) => a.code.localeCompare(b.code));
export const FIREBASE_VEHICLE_IMAGES = `${process.env.REACT_APP_FIREBASE_DIRECTORY}/vehicle`;
export const LANGUAGES = [
  {
    label: "한국어",
    value: "ko",
  },
  {
    label: "English",
    value: "en",
  },
  {
    label: "Español",
    value: "es",
  },
  {
    label: "русский язык",
    value: "ru",
  },
];
export const IMAGE_SIZE_LIMIT = 15728640; // 15MB
export const USER_LANGUAGE = "user-language";
export const USER_COUNTRY = "user-country";
